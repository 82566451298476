import React, { useState} from 'react'
import Cookies from 'universal-cookie'
import axios from 'axios'


import { Modal, Background } from './'

const cookies = new Cookies();

const initialState = {
    fullName: '',
    username: '',
    password: '',
    confirmPassword: '',
    avatarURL: '',
    authCode: ''
}

const Auth = () => {

    const [errorMessage, setErrorMessage] = useState('')
    const [form, setForm] = useState(initialState);
    const [isSignup, setIsSignup] = useState(true)
    const [openModal, setOpenModal] = useState(true);
    
    const handleChange = (e) => { 
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const {  username, password, confirmPassword, avatarURL, authCode } = form;

        if(isSignup) {
            if(password !== confirmPassword) {
                return setErrorMessage('Passwords do not match')
            }
            if(authCode !== 'AuthPass12321') {
                return setErrorMessage('Your auth code is not right. If you dont know what a auth code is then sign in.')
            }
        }

        const URL = "https://prochatbackend-ed3fe60f0b1b.herokuapp.com/auth";
        //https://prochatbackend-ed3fe60f0b1b.herokuapp.com/auth
        //http://localhost:5000/auth
        // const { data: { token, userId, hashedPassword, fullName} } = await axios.post(`${URL}/${isSignup ? 'signup' : 'login'}`, {
        //     username, password, fullName: form.fullName, avatarURL,
        // });


        try {
            setErrorMessage(`Loading...`)
            const { data: { token, userId, hashedPassword, fullName} } = await axios.post(`${URL}/${isSignup ? 'signup' : 'login'}`, {
                username, password, fullName: form.fullName, avatarURL,
            });
            cookies.set('token', token);
            cookies.set('username', username);
            cookies.set('fullName', fullName);
            cookies.set('userId', userId);

            if(isSignup) {
                cookies.set('avatarURL', avatarURL);
                cookies.set('hashedPassword', hashedPassword);
            }
            window.location.reload();
        } catch (error) {
            console.log(error.response.data.message)
            if (error.response.data.message === `User not found`) {
                setErrorMessage('Could not find user. Make sure your username is correct. If you believe your username is right you might have been banned lmao L mans. If you dont believe you were banned ask me and I will most likely tell you your username. Also make sure theres not a space at the end of your username ♥ ')
            } else if (error.response.data.message === `Incorrect password`) {
                setErrorMessage('Incorrect password')
            }
            else {
                setErrorMessage('An error occured either server ded, or code go breaky breaky. Hopefully the first one.')
                console.log(error)
            }
        }

        


        
    }

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
    }

   const resetPassword = async () => {
        console.log('Wants to reset password');
        let username = prompt('What is the user that you want to reset');
        let masterPassword = prompt('What is the master reset passsword? If you dont knwo ur a pleb tbh');
        let newPasssword = prompt('What is the password you want to reset to. ');

        const URL = "https://prochatbackend-ed3fe60f0b1b.herokuapp.com/auth";
        //https://prochatbackend-ed3fe60f0b1b.herokuapp.com/auth
        //http://localhost:5000/auth

        if(username && masterPassword && newPasssword){
            try {
                await axios.post(`${URL}/resetpassword`, {
                    username, resetCode: masterPassword, password: newPasssword
                })
                window.location.reload();
            } catch (error) {
                console.log(error.response.data.message)
            }
        }
   }

  return (
    
    <div className='auth__form-container'>
        <div>
            <Modal open={openModal} onClose={() => setOpenModal(false)}/>
        </div>
        <div className='backkground-auth' >
            <Background />
        </div>
        <div className='auth__form-container_fields'> 
            <div className='auth__form-container_fields-content'>
                <p>{isSignup ? 'Sign Up' : 'Sign In'}</p>
                <form onSubmit={handleSubmit}>
                        {isSignup && (
                            <div className='auth__form-container_fields-content_input'>
                                <label htmlFor='fullName'> Full Name </label>
                                <input 
                                    name="fullName"
                                    type="text"
                                    placeholder='Full Name'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            
                        )}
                        <div className='auth__form-container_fields-content_input'>
                                <label htmlFor='username'> Username </label>
                                <input 
                                    name="username"
                                    type="text"
                                    placeholder='Username'
                                    onChange={handleChange}
                                    required
                                />
                        </div>
                        {isSignup && (
                            <div className='auth__form-container_fields-content_input'>
                                <label htmlFor='avatarURL'> Avatar URL </label>
                                <input 
                                    name="avatarURL"
                                    type="text"
                                    placeholder='Avatar URL'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            
                        )}
                        <div className='auth__form-container_fields-content_input'>
                                <label htmlFor='password'> Password </label>
                                <input 
                                    name="password"
                                    type="password"
                                    placeholder='Password'
                                    onChange={handleChange}
                                    required
                                />
                        </div>
                        {isSignup && (
                            <div className='auth__form-container_fields-content_input'>
                                <label htmlFor='password'> Confirm Password </label>
                                <input 
                                    name="confirmPassword"
                                    type="password"
                                    placeholder='Password'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}
                        {isSignup && (
                            <div className='auth__form-container_fields-content_input'>
                                <label htmlFor='password'> Auth Code </label>
                                <input 
                                    name="authCode"
                                    type="password"
                                    placeholder='Auth Code'
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        )}
                        <div className='auth__form-container_fields-content_button'>
                            <button> {isSignup ? "Sign Up" : "Sign In"} </button>
                        </div>
                </form>
                <p className='auth__form-errorMessage' >{errorMessage}</p>
                <div className='auth__form-container_fields-account'>
                        <p>
                            {isSignup
                             ? "Already have an Account?"
                             : "Don't have an account?"
                            }
                            <span onClick={switchMode}>
                                {isSignup ? "Sign in" : "Sign Out"}
                            </span>
                            <br></br>
                            <span onClick={resetPassword}>
                               Forgor Password?
                            </span>
                        </p>
                        
                </div>
            </div>
        </div>
        {/* <div className='auth__form-container_image'>
            <img src={signinImage} alt="sign in"/>
        </div> */}
    </div>
  )
}

export default Auth