import React from 'react';
import { Avatar, useChatContext } from 'stream-chat-react';

const TeamChannelPreview = ({ setActiveChannel, setIsEditing, setIsCreating, setToggleContainer, channel, type }) => {

    const {channel : activeChannel, client} = useChatContext();


    var unreadCount = channel?.state?.unreadCount
    var num = unreadCount


    if (unreadCount >= 9){
        num = '9+'
    }

    var unreadOffset = ` - (${num})`
    

    const ChannelPreview = () => (
        <p className='channel-preview__item'>
            # {channel?.data?.name  || channel?.data?.id}
            {unreadCount >= 1 ? unreadOffset : ''}
        </p>
    );

    // console.log(`${unreadCount} ${channel?.data?.id}`)

    const DirectPreview = () => {
        const members = Object.values(channel.state.members).filter(({ user }) => user.id !== client.userID );

        return (
            <div className='channel-preview__item single'>
                <Avatar 
                    image={members[0]?.user?.image}
                    name={members[0]?.user?.fullName || members[0]?.user?.id}
                    size={24}
                />
                <p>{channel.data.name ? `${channel.data.name}  Group | ${channel.data.member_count} Members` : members[0]?.user?.fullName || members[0]?.user?.id  }  {unreadCount >= 1 ? unreadOffset : ''}</p>
                
            </div>
        )
    }

  return (
    <div className={
        channel?.id === activeChannel?.id
         ? 'channel-preview__wrapper__selected'
         : 'channel-preview__wrapper'
    }
    onClick={() => {
        setIsCreating(false);
        setIsEditing(false);
        setActiveChannel(channel)
        if(setToggleContainer){
            setToggleContainer((prevState) => !prevState)
        }
    }}
    >
        {type === 'team' ? <ChannelPreview /> : <DirectPreview/>}
    </div>
  )
}

export default TeamChannelPreview