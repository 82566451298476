import React, { useState } from 'react';
import { useChatContext } from 'stream-chat-react';

import { UserList } from './';
import { CloseCreateChannel } from '../assets';

const ChannelNameInput = ({ channelName = '', setChannelName }) => {
    const handleChange = (event) => {
        event.preventDefault();

        setChannelName(event.target.value);
    }

    return (
        <div className="channel-name-input__wrapper">
            <p>Name</p>
            <input value={channelName} onChange={handleChange} placeholder="channel-name" />
            <p>Add Members</p>
        </div>
    )
}

const EditChannel = ({ setIsEditing }) => {
    const { channel, client } = useChatContext();
    const [channelName, setChannelName] = useState(channel?.data?.name);
    const [selectedUsers, setSelectedUsers] = useState([])
    const [deleteChannelstate, setDeleteChannelstate] = useState(false)

    const updateChannel = async (event) => {
        event.preventDefault();

        const nameChanged = channelName !== (channel.data.name || channel.data.id);

        if(nameChanged) {
            await channel.update({ name: channelName }, { text: `Channel name changed to ${channelName}`});
        }

        if(selectedUsers.length) {
            await channel.addMembers(selectedUsers, {text: `A new user joined the channel.`});
        }

        setChannelName(null);
        setIsEditing(false);
        setSelectedUsers([]);
    }

    const deleteChannel = async () => {
        const promptUser = prompt("Are you sure you want to delete this channel? TRUE or FALSE")
        
        
        if(promptUser === 'TRUE')  {
            setDeleteChannelstate(true)
            channel.delete();
        } else {
            window.location.reload();
        }
    }
    

    

    if(client.user.role === 'admin') {
      return (
        <div className="edit-channel__container">
            <div> 

            </div>
            <div className="edit-channel__header">
                <p>Edit Channel</p>
                <CloseCreateChannel setIsEditing={setIsEditing} />
            </div>
            <ChannelNameInput channelName={channelName} setChannelName={setChannelName} />
            <UserList setSelectedUsers={setSelectedUsers} channel={channel} deleteChannelstate={deleteChannelstate} />
            <div className="edit-channel__button-wrapper" onClick={updateChannel}>
                <p>Save Changes</p>
            </div>
            <div className="edit-channel__button-wrapper-deleteChannel" onClick={deleteChannel}>
                <p>Delete Channel</p>
            </div>
        </div>
    )
    } else {
      return(
        <p className='unauthorized-text'> You are not allowed to edit Channels cus ur not me HAHA. Next time stand on businessss. Make sure you don't trip on your way off of this page.</p>
      )
    }

}

export default EditChannel