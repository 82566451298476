import React from 'react'
import { Avatar, useChatContext } from 'stream-chat-react'


const AvatarChanger = () => {
  const { client } = useChatContext();
  
  const handleClick= async () => {
   

      const avatarURL = prompt("Please enter an avatar url")
      if(avatarURL ){
        const update = { 
          id: client.userID, 
          set: { 
              image: avatarURL, 
          }, 
        } 
      await client.partialUpdateUser(update) 
      }
  }


  return (
    <div className='avatarChanger-Div' >
      <Avatar image={client.user.image} name={client.user.fullName || client.user.id} size={30} shape='round' onClick={handleClick} />
      <div className='avatarChanger-UserInfo' >
        <p className='avatarChanger-name' > {client.user.fullName} </p>
        <div className='avatarChanger-easter'>
          <p className='avatarChanger-username' > {client.user.name} </p>
          <p className='avatarChanger-easterEgg' > Made by Jaylen The Great </p>
        </div>

      </div>
    </div>
    
  )
}

export default AvatarChanger