import React, { useState } from 'react'
import { ChannelList, useChatContext, InfiniteScroll } from 'stream-chat-react'
import Cookies from 'universal-cookie';

import { ChannelSearch, TeamChannelList, TeamChannelPreview, UserOnlineList, AvatarChanger } from './'
import ProChatIcon from '../assets/ProChatIcon.png'
import LogoutIcon from '../assets/logout.png'


const cookies = new Cookies();

const Sidebar = ( {logout} ) => (
    <div className='channel-list__sidebar'>
        <div className='channel-list__sidebar__icon1'>
            <div className='icon1__inner'>
                <img src={ProChatIcon} alt = "ProIcon" width="30"/>
            </div>
        </div>
        <div className='channel-list__sidebar__icon2'>
            <div className='icon1__inner' onClick={logout}>
                <img src={LogoutIcon} alt = "Logout" width="30"/>
            </div>
        </div>
    </div>
)

const CompanyHeader = () => (
    <div className='channel-list__header'>
            <p className='channel-list__header__text'> Pro Chat </p>
    </div>
)

const customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'team')
}

const customChannelMessagingFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'messaging')
}

const  ChannelListContent = ({ isCreating ,setIsCreating ,setCreateType ,setIsEditing, setToggleContainer, reload }) => {

    const { client } = useChatContext();

    const logout = () => {
        cookies.remove("token");
        cookies.remove('userId');
        cookies.remove('username');
        cookies.remove('fullName');
        cookies.remove('avatarURL');
        cookies.remove('hashedPassword');

        window.location.reload();
    }

    const filters = { members: { $in: [client.userID]}}

  return (
    <>
        <Sidebar logout={logout} />
        <div className='channel-list__list__wrapper' >
                <CompanyHeader />
                <ChannelSearch  setToggleContainer={setToggleContainer} />
                <ChannelList 
                    filters={filters}
                    channelRenderFilterFn={customChannelTeamFilter}
                    List={(listProps) => (
                        <TeamChannelList 
                            {... listProps}
                            type="team"
                            isCreating={isCreating} 
                            setIsCreating ={setIsCreating}
                            setCreateType ={setCreateType}
                            setIsEditing={setIsEditing}
                            setToggleContainer={setToggleContainer} 
                        /> 
                    )}
                    Preview={(previewProps) => (
                        <TeamChannelPreview 
                            {... previewProps}
                            setIsCreating ={setIsCreating}
                            setIsEditing={setIsEditing}
                            setToggleContainer={setToggleContainer}
                            type="team" 
                        />
                    )}
                    Paginator={InfiniteScroll}           
                />
                <ChannelList  
                    filters={filters}
                    channelRenderFilterFn={customChannelMessagingFilter}
                    List={(listProps) => (
                        <TeamChannelList 
                            {... listProps}
                            type="messaging"
                            isCreating={isCreating} 
                            setIsCreating ={setIsCreating}
                            setCreateType ={setCreateType}
                            setIsEditing={setIsEditing}
                            setToggleContainer={setToggleContainer}
                        />
                    )}
                    Preview={(previewProps) => (
                        <TeamChannelPreview 
                            {... previewProps}
                            setIsCreating ={setIsCreating}
                            setIsEditing={setIsEditing}
                            setToggleContainer={setToggleContainer}
                            type="messaging"
                        />
                    )}      
                    Paginator={InfiniteScroll}             
                />
                <UserOnlineList reload={reload}  />
                <AvatarChanger />
        </div>
    </>
  );
}

const ChannelListContainer = ({ setCreateType, setIsCreating, setIsEditing }) => {
    const [toggleContainer, setToggleContainer] = useState(false);

    return (
        <>
            <div className="channel-list__container">
              <ChannelListContent 
                setIsCreating={setIsCreating} 
                setCreateType={setCreateType} 
                setIsEditing={setIsEditing} 
              />
            </div>

            <div className="channel-list__container-responsive"
                style={{ left: toggleContainer ? "0%" : "-87%", backgroundColor: "#005fff", width: toggleContainer ? "60%" : "89%"}}
            >
            <div className="channel-list__container-toggle" onClick={() => setToggleContainer((prevToggleContainer) => !prevToggleContainer)}>
                    
                </div>
                    <ChannelListContent 
                    setIsCreating={setIsCreating} 
                    setCreateType={setCreateType} 
                    setIsEditing={setIsEditing}
                    setToggleContainer={setToggleContainer}
                />
            </div>
        </>
    )

}

export default ChannelListContainer;