import React, { useState } from 'react'
import { StreamChat } from "stream-chat";
import { Chat } from "stream-chat-react";
import  Cookies  from "universal-cookie";


import { ChannelContainer, ChannelListContainer, Auth, Modal } from './components'

import 'stream-chat-react/dist/css/v2/index.css'
import './App.css';


//Hi if you are looking at this you are either me or someone that is looking in inspect element. If you are in inspect element or you are me then u are a cool dude :)


const cookies = new Cookies();

const apiKey = 'p7brb39dup42';
const authToken = cookies.get("token")



const client = StreamChat.getInstance(apiKey)

if(authToken) {
  client.connectUser({
    id: cookies.get('userId'),
    name: cookies.get('username'),
    fullName: cookies.get('fullName'),
    image: cookies.get('avatarURL'),
    hashedPassword: cookies.get('hashedPassword'),
  }, authToken)
}

// const users = await client.queryUsers(
//   { id: {$ne: client.userID} },
//   { id: -1 }, 
//   { presence: true }
// );
// console.log('Hii')
// console.log(users)

client.on("message.new", e => {
  var num = e.total_unread_count
  if(num >= 10) {
    num = '9+'
  }

  console.log(e.total_unread_count)
  console.log(num)

  console.log(e.total_unread_count)
  document.title = `(${num}) Pro Chat`
})

client.on("notification.mark_read", e => {
  var num = e.total_unread_count
  if(num >= 10) {
    num = '9+'
  }
  
  if(e.total_unread_count === 0) {
    document.title = "Pro Chat"
  } else  {
    document.title = `(${num}) Pro Chat`
  }
  num = e.total_unread_count
})


const App = () => {

  const [createType, setCreateType] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [openModal, setOpenModal] = useState(true);

  if(!authToken) return <Auth />

  return (
    <div className='app__wrapper'>
      <div>
        <Modal open={openModal} onClose={() => setOpenModal(false)}/>
      </div>
        <Chat client ={client} theme="str-chat__theme-light">
            <ChannelListContainer
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              setCreateType={setCreateType}
              setIsEditing={setIsEditing}
            />
            <ChannelContainer
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              createType={createType}
            />
        </Chat>
    </div>
  )
}

export default App