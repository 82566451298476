import React, { useEffect, useState } from 'react';
import { Avatar, useChatContext} from 'stream-chat-react'



const ListContainer = ({ children }) => {
    return (
        <div >
          {/* <div className='user-list__container'> */}
            <div className='user-list__header'>
                <p className='team-channel-list__header__title' >Online Users</p>
            </div>
            {children}
        </div>
    )
}

const UserItem = ({ user }) => {
    return (
        <div className='user-item__wrapper'>
            <div className='user-item__name-wrapper'>
                <Avatar image={user.image} name={user.fullName || user.id} size={25} shape='rounded' />
                <p className='userOnlineList__text'>{user.fullName || user.id}</p>
            </div>
        </div>
    )
}


const UserOnlineList = ({ reload }) => {
    const { client } = useChatContext();
    const [ userss, setUsers] = useState([]);
    const [ loading, setLoading] = useState(false);
    const [error, setError] = useState(false)

    const [test, setTest] = useState(false)


    client.on("user.presence.changed"  ,e => {
        setTest( test => !test)
    });

    useEffect(() => {
        const getUsers = async () => {
            if(loading) return;

            setLoading(true);

            
            

            try {
                const response = await client.queryUsers(
                    { id: {$ne: client.userID} }, // * $ne means not equal to 
                    { limit: 8 },
                    { presence: true}
                );



                for (let i = 0; i < response.users.length; i++) {
                  if(response.users[i].online === true) {
                     if(userss.find(e => e.id === response.users[i].id)) {
                     } else {
                        setUsers(userss =>  [...userss,  response.users[i]]);
                     }
                  } else {
                        if(userss.find(e => e.id === response.users[i].id)) {
                            const index = userss.findIndex(({ id }) => id === response.users[i].id);
                            if (index !== -1) {
                                setUsers([
                                  ...userss.slice(0, index),
                                  ...userss.slice(index + 1)
                                ]);
                              }
                        }
                    }
                }

            } catch(error) {
                console.log(error)
                setError(true)
            }
            setLoading(false);
        }
            
        if(client) getUsers();
    }, [test])




    if(error) {
        return(
            <ListContainer>
                <div className='user-list__message'>
                        Error loading, please refresh and try again.
                </div>
            </ListContainer>
        )
    }

    if(userss.length === 0) {
        return(
            <ListContainer>
                <div className='user-onlineList__message-no-onlineUsers'>
                       No other online users found.
                </div>
            </ListContainer>
        )
    }

//    client.on('user.presence.changed', e => {
//       console.log( e?.users)
//       console.log('user prescense changed')
//     });



    return (
        <div className='Online-user-list-div' >
            <ListContainer>
                    {loading ? <div className='user-list__message'>
                        Loading users...
                    </div> : (
                        userss?.map((user, i) => (
                            <UserItem index={i} key={user.id} user={user} />
                        ))
                    )}
            </ListContainer>
            
        </div>
    )
}

export default UserOnlineList;