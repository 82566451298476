import React from 'react'

const Modal = ({open, onClose}) => {
  
    if(!open) return null

    return (
    <div onClick={onClose} className='overlay'>
        <div className="modalContainer">
            <div onClick={(e) => {
                e.stopPropagation();
            }}  className="modalRight">
                <p  onClick={onClose} className='closeBtn'>X</p>
                <div className='content'>
                    <h1 className='modalTitle'> Pro Chat</h1>
                    <p className='modalBody' >  ProChat is to be used only when you are finished with school work and you have permission to get on websites. By continuing you agree that you are done with all work and you have permission to use this site.
                            <br></br>While using Prochat you must follow the school rules and student handbook. Failure to abide by these rules will result in your account being terminated.
                    </p>
                    <p className='modalBody'>  </p>
                    <p className='modalBody' > Note to ECHS Tech Department: Hi, this website is made to allow students to communicate between each other when done with school work. You can test this app by signing in with Username: ECHSTECH and Password: ECHSTECH123. <br></br> If you have any questions or concerns feel free to contact me (Jaylen Walters) :) </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Modal