import React, { useEffect, useState } from 'react';
import { Avatar, useChatContext} from 'stream-chat-react'

import { InviteIcon } from '../assets';

const ListContainer = ({ children }) => {

    return (
        <div className='user-list__container'>
            <div className='user-list__header'>
                <p>User</p>
                <p>Invite/Invited</p>
            </div>
            {children}
        </div>
    )
}

const UserItem = ({ user, setSelectedUsers, channel, setIsCreating, deleteChannelstate }) => {
    const [selected, setSelected] = useState(false)

    const handleSelect = () => {
        if(selected) {
            setSelectedUsers((prevUsers) => prevUsers.filter((prevUser) => prevUser !== user.id))
        } else {
            setSelectedUsers((prevUsers) => [...prevUsers, user.id])
        }

        setSelected((prevSelected) => !prevSelected)
    }


    if(!setIsCreating && !deleteChannelstate){
        var userInChannel = Object?.keys(channel?.state?.members).includes(user?.id)
    }
    

    return (
        <div className='user-item__wrapper' onClick={handleSelect}>
            <div className='user-item__name-wrapper'>
                <Avatar image={user.image} name={user.fullName || user.id} size={32}/>
                <p className='team-channel-header__name user'>{user.fullName || user.id} </p>
                <p className='team-channel-header__name user'>{`(${user.id})`}</p>
            </div>

            {selected ? <InviteIcon /> : <div className='user-item__invite-empty' />}
            <p>  -||||||||-  </p>
            {userInChannel ? <InviteIcon /> : <div className='user-item__invite-empty' />}
            
        </div>
    )
}

const UserList = ({ setSelectedUsers, channel, setIsCreating, deleteChannelstate}) => {
    const { client } = useChatContext();
    const [ users, setUsers] = useState([]);
    const [ loading, setLoading] = useState(false);
    const [ listEmpty, setListEmpty] = useState(false);
    const [error, setError] = useState(false)

    useEffect(() => {
        const getUsers = async () => {
            if(loading) return;

            setLoading(true);

            try {
                const response = await client.queryUsers(
                    { id: {$ne: client.userID} }, // * $ne means not equal to 
                    { id: 1 },
                );

                if(response.users.length){
                    setUsers(response.users);
                } else {
                    setListEmpty(true)
                }
            } catch(error) {
                setError(true)
            }
            setLoading(false);
        }

        if(client) getUsers();
    }, [])

    if(error) {
        return(
            <ListContainer>
                <div className='user-list__message'>
                        Error loading, please refresh and try again.
                </div>
            </ListContainer>
        )
    }

    if(listEmpty) {
        return(
            <ListContainer>
                <div className='user-list__message'>
                       No users found.
                </div>
            </ListContainer>
        )
    }

    return (
        <div>
            <ListContainer>
                    {loading ? <div className='user-list__message'>
                        Loading users...
                    </div> : (
                        users?.map((user, i) => (
                            <UserItem index={i} key={user.id} user={user} setSelectedUsers={setSelectedUsers} channel={channel} setIsCreating={setIsCreating} deleteChannelstate={deleteChannelstate} />
                        ))
                    )}
            </ListContainer>
            
        </div>
    )
}

export default UserList;